import * as React from "react"
import { Frame, addPropertyControls, ControlType } from "framer"
// Import extended font controls without modifications
import { fontControls } from "https://framer.com/m/framer/default-utils.js@^0.45.0"

export function CountUpDown(props) {
    const {
        targetNumber,
        duration,
        countDirection,
        font,
        fontColor,
        delay,
        startPlaying,
        easeOut,
    } = props
    const [count, setCount] = React.useState(
        countDirection === "up" ? 0 : targetNumber
    )

    React.useEffect(() => {
        if (!startPlaying) return

        let timeoutId = setTimeout(() => {
            let start = null
            const endCount = countDirection === "up" ? targetNumber : 0

            const animate = (timestamp) => {
                if (!start) start = timestamp
                const runtime = timestamp - start
                const relativeProgress = runtime / (duration * 1000)
                const progress = Math.min(relativeProgress, 1)

                let easeProgress
                switch (easeOut) {
                    case "no":
                        easeProgress = progress
                        break
                    case "low":
                        easeProgress = Math.sin((progress * Math.PI) / 2)
                        break
                    case "medium":
                        easeProgress = 1 - Math.pow(1 - progress, 2)
                        break
                    case "high":
                        easeProgress = 1 - Math.pow(1 - progress, 3)
                        break
                    default:
                        easeProgress = progress
                        break
                }

                const currentCount =
                    countDirection === "up"
                        ? easeProgress * endCount
                        : targetNumber - easeProgress * targetNumber

                // Set count with support for decimals
                setCount(parseFloat(currentCount.toFixed(2)))

                if (runtime < duration * 1000) {
                    requestAnimationFrame(animate)
                }
            }

            requestAnimationFrame(animate)
        }, delay * 1000)

        return () => clearTimeout(timeoutId)
    }, [targetNumber, duration, countDirection, startPlaying, delay, easeOut])

    const textStyle = {
        ...font,
        color: fontColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
    }

    return <div style={textStyle}>{count}</div>
}

CountUpDown.defaultProps = {
    targetNumber: 10,
    duration: 10,
    countDirection: "up",
    font: fontControls.defaultProps,
    fontColor: "#000",
    delay: 0,
    startPlaying: false,
    easeOut: "no",
}

addPropertyControls(CountUpDown, {
    font: {
        // @ts-expect-error – Internal
        type: ControlType.Font,
        title: "Font",
        defaultValue: "Helvetica, 16px, #000000ff", // Replace with your default value
        // @ts-expect-error – Internal

        controls: "extended", // This enables extended font controls
    },
    targetNumber: {
        title: "Target Number",
        type: ControlType.Number,
        defaultValue: CountUpDown.defaultProps.targetNumber,
        step: 0.01, // Allows for decimal input
    },
    duration: {
        title: "Duration (s)",
        type: ControlType.Number,
        defaultValue: CountUpDown.defaultProps.duration,
        step: 0.01, // Allows for decimal input for duration as well
    },
    countDirection: {
        title: "Direction",
        type: ControlType.SegmentedEnum,
        options: ["up", "down"],
        optionTitles: ["Count Up", "Count Down"],
        defaultValue: CountUpDown.defaultProps.countDirection,
    },
    delay: {
        title: "Delay (s)",
        type: ControlType.Number,
        defaultValue: CountUpDown.defaultProps.delay,
        step: 0.01, // Allows for decimal input for delay
    },
    startPlaying: {
        title: "Start Playing",
        type: ControlType.Boolean,
        defaultValue: CountUpDown.defaultProps.startPlaying,
    },
    easeOut: {
        title: "Ease Out",
        type: ControlType.Enum,
        options: ["no", "low", "medium", "high"],
        optionTitles: ["No", "Low", "Medium", "High"],
        defaultValue: CountUpDown.defaultProps.easeOut,
    },
    fontColor: {
        title: "Font Color",
        type: ControlType.Color,
        defaultValue: CountUpDown.defaultProps.fontColor,
    },
})
